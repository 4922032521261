import IMAGES from './images'

const portfolio = [
  {
    title: '2020 TX Ballot Tracker',
    subtitle: 'Grassroots voter turnout app',
    images: IMAGES.txballottracker,
    link: 'https://bit.ly/TXBallotTracker',
    description:
      'Released an app 36 hours before the polls closed that enabled people to encourage their friends to vote. App went viral w/ ~3,000 users who made over 249K searches of their peers',
    chips: [],
    bullets: [],
  },
  {
    title: 'Uplift Journal',
    subtitle: 'Mental health app',
    images: IMAGES.uplift,
    link: 'https://www.youtube.com/watch?v=LFK-0HCpzJk',
    description:
      "Journal app that aimed to understand someone's psychology to provide more holistic mental healthcare",
    chips: [
      {
        label: 'Won award',
        color: ['lime'],
      },
      {
        label: 'Ongoing',
        color: ['amber'],
      },
    ],
    bullets: [
      'My favorite project of all time!',
      'My (written + audio) journal is over 2.5 million words, so I wanted to analyze how I was growing over time to deal with depression more effectively',
      'This was my undergrad thesis, as well as keystone project when applying to Harvard',
      'Checkout the YouTube video by clicking on the launch icon at the top :)',
    ],
  },
  {
    title: 'UT Dorm Speedy Mail Logger',
    subtitle: 'Improving a painfully tedious process',
    images: IMAGES.mail,
    link: null,
    description:
      'Streamlined envelope validation by 76% and package logging by 52%. App adopted by 58 dorm employees, logging ~6,900 packages during its lifetime',
    chips: [
      {
        label: 'Won award',
        color: ['lime'],
      },
    ],
    bullets: [],
  },
  {
    title: 'Satya Steganography',
    subtitle: 'Encrypting files within images',
    images: IMAGES.satya,
    link:
      'https://abstracts.societyforscience.org/Home/FullAbstract?ISEFYears=2015%2C&Category=Any%20Category&AllAbstracts=True&FairCountry=Any%20Country&FairState=Any%20State&ProjectId=12352',
    description:
      'Placed Finalist in the Intel International Science and Engineering Fair (ISEF) 2015.',
    chips: [
      {
        label: 'Won award',
        color: ['lime'],
      },
    ],
    bullets: [
      'This image is actually encrypted with my old journal!',
      'Built this using Python and wrote a dissertation on the research I did',
    ],
  },
  {
    title: 'Graduation Tracker',
    subtitle: 'Specifically multiple majors/minors',
    images: IMAGES.degreeaudit,
    link: null,
    description:
      'I dual majored and dual minored, and no existing tool was good at making sure I could graduate on time with all 4, so I built my own app',
    chips: [],
    bullets: [],
  },
  {
    title: 'Carpe Diem Scheduling',
    subtitle: 'Hyper-effective time management',
    images: IMAGES.carpediem,
    link: null,
    description:
      'Very ambitious app, and the only app prototype that I never finished due to time constraints, ironically.',
    chips: [],
    bullets: [],
  },
  {
    title: 'City Navigator',
    subtitle: 'for Robotics competition',
    images: IMAGES.robot,
    link: null,
    description:
      'Point-and-click interface for auto-programming the route a robot dog should follow on a real-life map',
    chips: [
      {
        label: 'Won award',
        color: ['lime'],
      },
    ],
    bullets: [],
  },
]

export default portfolio
