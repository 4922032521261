import IMAGES from './images'

const experience = [
  {
    title: 'Harvard Business School',
    subtitle: 'MBA Candidate',
    images: IMAGES.harvard,
    link: null,
    description: '',
    chips: [
      {
        label: 'Deferred',
        color: ['amber'],
      },
    ],
    bullets: [
      'Got accepted through the HBS 2+2 Program',
      'This means I have 2 (up to 4) years of deferral before entering the 2-year MBA',
      'Check out my YouTube channel for more videos / advice on this!',
    ],
    hide: true,
  },
  {
    title: 'Facebook',
    subtitle: 'Solutions Engineer',
    images: IMAGES.facebook,
    link: null,
    description:
      'Hybrid business / coding job, where I work with clients and use my software engineering skills to unlock revenue',
    chips: [
      {
        label: 'Current',
        color: ['amber'],
      },
    ],
    bullets: [
      'I work on taking early-stage products and scaling them to thousands of advertisers at once, both through building infrastructure and directly working with high-priority clients',
      "Super cool position -- if you're a Software Engineer looking to learn business, try this role out",
      "If you're in business and want to learn how to do this type of work without coding, try the analogous 'Solutions Architect' role",
    ],
  },
  {
    title: 'Google',
    subtitle: 'Software Engineer Intern',
    images: IMAGES.google,
    link: 'https://cloud.google.com/logging/docs/view/overview',
    description:
      'Spearheaded 2 mission-critical features needed by Spotify and 30 other clients to adopt Google Cloud. Presented to ~300 engineers/product managers at the Google Cloud Summit',
    chips: [],
    bullets: [
      'Built 2 frontend features within Google Cloud Platform: Log Pinning and Log Downloading',
      "Spotify said they wouldn't switch to GCP unless these 2 features (+8 more) were built",
      'Pivoted the direction of these features after getting initial user feedback',
    ],
  },
  {
    title: 'University of Oxford',
    subtitle: 'Research Intern',
    images: IMAGES.oxford,
    link:
      'http://docplayer.net/8419503-2014-siemens-competition-math-science-technology-semifinalists.html',
    description:
      'Bioinformatics research, evaluating the human genome to determine patterns correlated with cancer with regards to introns and exons',
    chips: [
      {
        label: 'Won award',
        color: ['lime'],
      },
    ],
    bullets: [
      'Independent, remote research I did in high school with a University of Oxford researcher, Dr. Grant Belgard',
      'Research placed Semifinalist in the 2014 Siemens Competition (see link)',
      "The coding part of this project was fun, but the deeper I got into the heavy biology, the more I realized I didn't like research",
    ],
  },
]

export default experience
