import experience from './experience'
import community from './community'
import portfolio from './portfolio'
// import resumes from './resumes'

const sections = [
  {
    list: experience,
    title: 'Experience',
    subtitle: null,
    link: 'experience',
  },
  {
    list: portfolio,
    title: 'App Portfolio',
    subtitle: 'Passion projects makes me really happy :)',
    link: 'projects',
  },
  {
    list: community,
    title: 'Community Engagement',
    subtitle: null,
    link: 'community',
  },
  // {
  //   list: resumes,
  //   title: 'Resumes',
  //   subtitle:
  //     "For those who've asked to see my resumes, here are all versions so you can see how I've grown over time. Click 'Learn More' to see my resume tips!",
  //   link: 'resumes',
  // },
]

export default sections
