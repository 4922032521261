import _ from 'lodash'
import React from 'react'
import { withSnackbar } from 'notistack'

import { makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import Typography from '@material-ui/core/Typography'

import { FONTS } from '../utils/core'
import Card from '../components/card_w_header'
import Colors from '../utils/colors'
import Page from './page'

import sections from '../data/sections'

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
  },
  sections: {
    width: '100%',
  },
  section: {
    marginTop: '1em',
    width: '100%',
  },
  sectionHeader: {
    position: 'sticky',
    top: '96px', // Header seems to be exactly 96px (plus margin-top on page content is also 96px)
    paddingBottom: 2.5,
    zIndex: 1,
    backgroundColor: Colors.get('grey', 100),
    '& a': {
      fontFamily: FONTS.primary,
      fontWeight: 500,
      textDecoration: 'none',
      color: Colors.get('grey', 800),
    },
  },
  sectionSubtitle: {
    fontFamily: FONTS.primary,
    fontWeight: 300,
  },
  cards: {
    width: '100%',
  },
  finalElement: {
    paddingBottom: '125px',
  },
}))

function Portfolio() {
  const classes = useStyles()

  function content() {
    return (
      <Grid container className={classes.container}>
        <Grid item className={classes.sections}>
          {_.map(sections, (section, idx) => {
            return (
              <div key={idx} className={classes.section}>
                <Typography variant="h6" className={classes.sectionHeader}>
                  <a id={section.link} href={'#' + section.link}>
                    {section.title}
                  </a>
                </Typography>
                {section.subtitle ? (
                  <Typography
                    variant="subtitle1"
                    className={classes.sectionSubtitle}
                  >
                    {section.subtitle}
                  </Typography>
                ) : null}{' '}
                <GridList
                  cellHeight={'auto'}
                  cols={100}
                  className={classes.cards}
                >
                  {_.map(section.list, (info, sub_idx) => {
                    if (info.hide) return null
                    return (
                      <GridListTile key={sub_idx}>
                        <Card {...info} />
                      </GridListTile>
                    )
                  })}
                </GridList>
                {idx + 1 < sections.length ? (
                  <Divider variant="middle" style={{ margin: '0.5em' }} />
                ) : null}
              </div>
            )
          })}
        </Grid>
      </Grid>
    )
  }

  return <Page content={content()} />
}

export default withSnackbar(Portfolio)
