import IMAGES from './images'

const community = [
  {
    title: 'Texas 4000 for Cancer',
    subtitle: 'Fundraising Coordinator',
    images: IMAGES.t4k,
    link: 'https://www.texas4000.org/rider/2019/ozarks/shiva-velingker/',
    description: 'Biked over 4,000 miles from Texas to Alaska to fight cancer!',
    chips: [
      {
        label: 'Nonprofit',
        color: ['pink', 200],
      },
    ],
    bullets: [
      'Trained cancer-surviving kids for a 5K through weekly workout sessions to rebuild their stamina and self-confidence',
      'Built novel apps to automate tedious team management and improve efficiency',
      'Strategized new strategies to promote effective team fundraising (e.g., including analytics from Bitly)',
      'Facilitated discussions on inclusivity and team culture to alleviate unspoken sentiments of isolation and inauthenticity',
      'Our team (over 80 riders) fundraised over $1 million to fight cancer',
      'Check out my Insta (@shivalicious) for the whole journey!',
    ],
  },
  {
    title: 'Travis County Government',
    subtitle: 'Poll Worker',
    images: IMAGES.travis,
    link: null,
    description:
      'Served as a poll worker during the US national and local elections in 2020-2021',
    chips: [],
    bullets: [],
  },
  {
    title: 'Out Youth',
    subtitle: 'Project Manager',
    images: IMAGES.outyouth,
    link: null,
    description:
      'Manage marketing campaigns to help this 31yo nonprofit build its first recurring donor base',
    chips: [
      {
        label: 'Nonprofit',
        color: ['pink', 200],
      },
      {
        label: 'Ongoing',
        color: ['amber'],
      },
    ],
    bullets: [],
  },
  {
    title: 'MEASURE',
    subtitle: 'T-Shirt Fundraise Coordinator',
    images: IMAGES.measure,
    link: 'https://www.facebook.com/wemeasureus/posts/1720181831468391',
    description:
      "Coordinated 44 Facebook employees to support a BLM nonprofit fundraiser in the wake of George Floyd's death. Drove over 8.2K people to t-shirt",
    chips: [
      {
        label: 'Nonprofit',
        color: ['pink', 200],
      },
    ],
    bullets: [],
  },
  {
    title: 'Dell Medical School',
    subtitle: 'Project Manager',
    images: IMAGES.dell,
    link: null,
    description:
      'Provided technical expertise for development switching costs of newly-adopted learning management system',
    chips: [],
    bullets: [],
  },
  {
    title: 'ADVANCE Program for Young Scholars',
    subtitle: 'Camp Counselor',
    images: IMAGES.advance,
    link: null,
    description:
      '“You were a great RA and an even better role model” -one of my kids. This quote really meant a lot to me :)',
    chips: [
      {
        label: '2 years',
        color: ['pink', 200],
      },
    ],
    bullets: [
      'Mentored 12, thirteen-year-old male students attending a gifted residential program, all living away from home for the first time',
      'Fostered a nurturing environment and taught the kids life lessons in self-management and social interactions',
      'Collaborated with 32 residential and academic staff members to ensure 165 kids’ wellbeing and performance to “create the magic”',
    ],
  },
]

export default community
